body {
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden; }

body.home {
  background: white; }

.home {
  text-align: center; }
  .home .search__input {
    border: none;
    border-bottom: 1px solid #F37335;
    padding: 10px 0;
    text-transform: uppercase;
    letter-spacing: 3px;
    min-width: 400px;
    outline: none;
    color: #F37335;
    margin-bottom: 120px;
    text-align: center; }
  .home__title {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 40px;
    letter-spacing: 20px;
    color: #F37335;
    opacity: 0.9;
    margin: 150px 0 50px 0;
    padding: 0 40px; }
    .home__title span {
      display: block;
      margin-top: 10px;
      font-size: 15px;
      letter-spacing: 10px; }
  .home__albums {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap; }
  .home__album {
    width: 25%;
    position: relative;
    cursor: pointer; }
    .home__album img {
      display: -webkit-flex;
      display: flex;
      width: 100%;
      border-radius: 15px;
      height: auto; }
    .home__album__info {
      position: absolute;
      display: -webkit-flex;
      display: flex;
      left: 0;
      top: 0;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      border: 5px solid white;
      border-radius: 10px;
      background: -webkit-linear-gradient(bottom, rgba(253, 200, 48, 0.8), rgba(243, 115, 53, 0.8));
      background: linear-gradient(to top, rgba(253, 200, 48, 0.8), rgba(243, 115, 53, 0.8));
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .home__album__info__name {
        position: relative;
        display: -webkit-flex;
        display: flex;
        margin: 10px;
        padding: 10px 15px;
        background: white;
        border-radius: 30px;
        font-weight: 400;
        color: #F37335;
        font-size: 20px;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        box-sizing: border-box; }
        .home__album__info__name:after {
          content: '';
          position: absolute;
          display: -webkit-flex;
          display: flex;
          left: 0;
          top: 0;
          width: calc(100% - 20px);
          height: calc(100% - 20px);
          border: 10px solid white;
          border-radius: 30px; }
  @media only screen and (max-width: 900px) {
    .home__album {
      width: 33%; }
      .home__album__info__name {
        font-size: 14px;
        padding: 10px;
        margin: 0;
        font-weight: 700;
        text-overflow: initial;
        white-space: normal;
        white-space: initial;
        background: transparent;
        color: #fff; }
        .home__album__info__name:after {
          display: none; } }
  @media only screen and (max-width: 500px) {
    .home__album {
      width: 50%; } }

.back {
  position: absolute;
  cursor: pointer;
  padding: 20px;
  top: 23px;
  z-index: 1; }

.player {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1000; }
  .player__bar {
    width: 0;
    height: 5px;
    background-color: #F37335;
    -webkit-animation: bar 30s linear;
            animation: bar 30s linear; }

.product {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .product__title {
    display: -webkit-flex;
    display: flex;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 40px;
    letter-spacing: 20px;
    color: #F37335;
    opacity: 0.9;
    margin: 30px 0 0 0;
    text-align: center;
    padding: 0 40px; }
  .product__artist {
    display: -webkit-flex;
    display: flex;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 3px;
    color: #F37335;
    opacity: 0.9;
    margin: 10px 0 40px; }
  .product__track {
    display: -webkit-flex;
    display: flex;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 3px;
    color: #F37335;
    opacity: 0.9;
    padding: 15px 0;
    margin: 0;
    cursor: pointer; }
    .product__track.-disabled {
      text-decoration: line-through;
      pointer-events: none; }
    .product__track:not(:last-child) {
      border-bottom: 1px solid rgba(243, 115, 53, 0.3); }
  .product__details {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    color: #F37335;
    margin-bottom: 40px; }
  .product__music {
    position: absolute;
    bottom: 0px;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 3px;
    color: #F37335;
    opacity: 0.9;
    margin: 0;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .product__image, .product__image--playing {
    display: -webkit-flex;
    display: flex;
    position: relative;
    width: 380px;
    height: 380px;
    margin-right: 120px;
    padding-bottom: 40px;
    overflow: hidden; }
    .product__image .pause, .product__image--playing .pause {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      z-index: 100; }
    .product__image .disc, .product__image--playing .disc {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      -webkit-transition: -webkit-transform 1s;
      transition: -webkit-transform 1s;
      transition: transform 1s;
      transition: transform 1s, -webkit-transform 1s; }
    .product__image:before, .product__image--playing:before {
      content: '';
      border-radius: 100%;
      position: absolute;
      width: 50px;
      height: 50px;
      background: #fff;
      left: 50%;
      top: calc(50% - 20px);
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      z-index: 100; }
    .product__image--playing, .product__image--playing--playing {
      cursor: pointer; }
      .product__image--playing .disc, .product__image--playing--playing .disc {
        -webkit-animation: rotating 2.5s linear infinite;
                animation: rotating 2.5s linear infinite;
        opacity: 0.7;
        -webkit-transition: all 1s;
        transition: all 1s; }
  @media only screen and (max-width: 1024px) {
    .product__title {
      font-size: 30px; }
    .product__details {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
    .product__image, .product__image--playing {
      margin-right: 0;
      margin-bottom: 80px; }
    .product__track {
      padding: 15px 20px; }
    .product__details {
      margin-bottom: 30px; } }
  @media only screen and (max-width: 500px) {
    .product__image, .product__image--playing {
      width: 250px;
      height: 250px; } }

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes bar {
  from {
    width: 0; }
  to {
    width: 100%; } }

@keyframes bar {
  from {
    width: 0; }
  to {
    width: 100%; } }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  max-width: 100% !important;
  overflow-x: hidden !important;
}
